import "../styles/styles.scss";
import type { AppProps } from "next/app";
import { useRef } from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { Sora } from "@next/font/google";

const sora = Sora({
  weight: ["300"],
  style: "normal",
  display: "swap",
});

export default function App({ Component, pageProps }: AppProps) {
  const containerRef = useRef(null);
  const options = {
    smooth: true,
    multiplier: 1,
    getSpeed: true,
    getDirection: true,
    reloadOnContextChange: true,
    touchMultiplier: 3,
    smoothMobile: 0,
    smartphone: {
      breakpoint: 0,
      smooth: true,
      multiplier: 3,
      touchMultiplier: 5,
      lerp: 1,
    },
    tablet: {
      breakpoint: 768,
      smooth: true,
      multiplier: 3,
      lerp: 1,
    },
  };
  return (
    <LocomotiveScrollProvider
      options={options}
      watch={
        [
          //..all the dependencies you want to watch to update the scroll.
          //  Basicaly, you would want to watch page/location changes
          //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
        ]
      }
      containerRef={containerRef}
    >
      <style jsx global>{`
        span,
        p {
          font-family: ${sora.style.fontFamily};
        }
      `}</style>
      <main data-scroll-container ref={containerRef}>
        <Component {...pageProps} />
      </main>
    </LocomotiveScrollProvider>
  );
}
